import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  title = 'Lumina';

  h1scroll = {
    'top': {'coords': [30, 1], 'mob': [40, 1], 'unite': '%', 'unite_mob': '%'},
    'font-size': {'coords': [34, 5], 'mob': [30, 12], 'unite': 'vh', 'unite_mob': 'vw'},
  };
  boulesmagiques = [
    {
      'top': {'coords': [58, 70], 'mob': [50, 78], 'unite': 'vh'},
      'left': {'coords': [38, 80], 'mob': [28, 140], 'unite': 'vw'},
    },
    {
      'top': {'coords': [38, 80], 'mob': [40, 75], 'unite': 'vh'},
      'left': {'coords': [48, 10], 'mob': [45, -40], 'unite': 'vw'},
    },
    {
      'top': {'coords': [57, 50], 'mob': [49, 140], 'unite': 'vh'},
      'left': {'coords': [58, 30], 'mob': [63, 80], 'unite': 'vw'},
    },
  ]
  infosTarget = 'coords';
  uniteTarget = 'unite';

  h1style = {};
  boulesstyle = [{},{},{}];

  shake1 = '';
  shake2 = '';
  shake3 = '';

  ngOnInit(){
    window.addEventListener('scroll', this.scrollEvent, true);
    if(window.innerWidth <= 1024){
      this.infosTarget = 'mob';
      this.uniteTarget = 'unite_mob';
      this.shake1 = 'shake-stone1_mob';
      this.shake2 = 'shake-stone2_mob';
      this.shake3 = 'shake-stone3_mob';
    }else{
      this.infosTarget = 'coords';
      this.uniteTarget = 'unite';
      this.shake1 = 'shake-stone1';
      this.shake2 = 'shake-stone2';
      this.shake3 = 'shake-stone3';
    }
  }
  ngOndestroy(){
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  public scrollSmoothToBottom (id) {
    var div = document.documentElement;
    $('html').animate({
      scrollTop: div.scrollHeight - div.clientHeight
    }, 500);
  }

  scrollEvent = (event: any): void => {
    var maxh1 = 60;
    var h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';

    var percent = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;

    if(percent < maxh1){
      var h1percent = percent*(100/maxh1);
      for(let [attr, data] of Object.entries(this.h1scroll)){
        var value = ((data[this.infosTarget][1] - data[this.infosTarget][0]) / 100) * h1percent + data[this.infosTarget][0];
        this.h1style[attr] = value.toString() + data[this.uniteTarget];
      }
    }else{
      for(let [attr, data] of Object.entries(this.h1scroll)){
        this.h1style[attr] = data[this.infosTarget][1].toString() + data[this.uniteTarget];
      }
    }

    for (let i= 0; i<3; i++){
      for(let [attr, data] of Object.entries(this.boulesmagiques[i])){
        var value = ((data[this.infosTarget][1] - data[this.infosTarget][0]) / 100) * percent + data[this.infosTarget][0];
        this.boulesstyle[i][attr] = value.toString() + data['unite'];
      }
    }
  }
}
